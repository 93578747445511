<div id="layoutSidenav_content">
  <main>
      <div class="container-fluid px-4">
          <h1 class="cat-head">Admin List</h1>
          <div class="page-head">30 Admins
              <div class="filter-block">
                  <label>Filter by:</label>
                  <div class="form-box">
                      <select class="form-select">
                          <option selected>Select Admin</option>
                          <option value="1">Admin 1</option>
                          <option value="2">Admin 2</option>
                      </select>
                  </div>
                  <div class="form-box"><span>From:</span><input type="date" class="form-control" placeholder="Date"></div>
                  <div class="form-box"><span>To:</span><input type="date" class="form-control" placeholder="Date"></div>
              </div>
          </div>
          <div class="table-box">
              <table class="table-style">
                  <thead>
                      <tr>
                          <th><input type="checkbox" name =""></th>
                          <th>Admin Id</th>
                          <th>Admin name</th>
                          <th>Role</th>
                          <th>Permissions</th>
                          <th>Created on</th>
                          <th></th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr>
                          <td><input type="checkbox" name =""></td>
                          <td>231</td>
                          <td>Amit Kumar</td>
                          <td>Contributor</td>
                          <td>Read, Edit</td>
                          <td>20-05-2021</td>
                          <td class="admin-actoin"><a href="#" class="btn btn-outlined-dark">Edit</a><a href="#" class="btn btn-light">Suspend</a><a href="#" class="btn btn-danger">Delete Profile</a></td>
                      </tr>
                      <tr>
                          <td><input type="checkbox" name =""></td>
                          <td>231</td>
                          <td>Amit Kumar</td>
                          <td>Contributor</td>
                          <td>Read, Edit</td>
                          <td>20-05-2021</td>
                          <td class="admin-actoin"><a href="#" class="btn btn-outlined-dark">Edit</a><a href="#" class="btn btn-light">Suspend</a><a href="#" class="btn btn-danger">Delete Profile</a></td>
                      </tr>
                      <tr>
                          <td><input type="checkbox" name =""></td>
                          <td>231</td>
                          <td>Amit Kumar</td>
                          <td>Contributor</td>
                          <td>Read, Edit</td>
                          <td>20-05-2021</td>
                          <td class="admin-actoin"><a href="#" class="btn btn-outlined-dark">Edit</a><a href="#" class="btn btn-light">Suspend</a><a href="#" class="btn btn-danger">Delete Profile</a></td>
                      </tr>
                      <tr>
                          <td><input type="checkbox" name =""></td>
                          <td>231</td>
                          <td>Amit Kumar</td>
                          <td>Contributor</td>
                          <td>Read, Edit</td>
                          <td>20-05-2021</td>
                          <td class="admin-actoin"><a href="#" class="btn btn-outlined-dark">Edit</a><a href="#" class="btn btn-light">Suspend</a><a href="#" class="btn btn-danger">Delete Profile</a></td>
                      </tr>
                      <tr>
                          <td><input type="checkbox" name =""></td>
                          <td>231</td>
                          <td>Amit Kumar</td>
                          <td>Contributor</td>
                          <td>Read, Edit</td>
                          <td>20-05-2021</td>
                          <td class="admin-actoin"><a href="#" class="btn btn-outlined-dark">Edit</a><a href="#" class="btn btn-light">Suspend</a><a href="#" class="btn btn-danger">Delete Profile</a></td>
                      </tr>
                  </tbody>
              </table>
          </div>
      </div>
  </main>

</div>
