import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { PendingListingsComponent } from './components/pending-listings/pending-listings.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { ApprovedAdsComponent } from './components/approved-ads/approved-ads.component';
import { PausedAdsComponent } from './components/paused-ads/paused-ads.component';
import { DeclinedAdsComponent } from './components/declined-ads/declined-ads.component';
import { DeletedAdsComponent } from './components/deleted-ads/deleted-ads.component';
import { VerifiedUserComponent } from './components/verified-user/verified-user.component';
import { UnverifiedUserComponent } from './components/unverified-user/unverified-user.component';
import { DeletedUserComponent } from './components/deleted-user/deleted-user.component';
import { AdminListComponent } from './components/admin-list/admin-list.component';
import { BrandsComponent } from './components/brands/brands.component';
import { ModelsComponent } from './components/models/models.component';
import { AddAdminComponent } from './components/add-admin/add-admin.component';
import { RemoveAdminComponent } from './components/remove-admin/remove-admin.component';
import { AdminLogComponent } from './components/admin-log/admin-log.component';
import { AdDetailComponent } from './components/ad-detail/ad-detail.component';
import { AdListUserComponent } from './components/ad-list-user/ad-list-user.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AdsComponent } from './components/ads/ads/ads.component';
import { RedirectAdComponent } from './components/redirect-ad/redirect-ad.component';
@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        PendingListingsComponent,
        SidebarComponent,
        TopbarComponent,
        ApprovedAdsComponent,
        PausedAdsComponent,
        DeclinedAdsComponent,
        DeletedAdsComponent,
        VerifiedUserComponent,
        UnverifiedUserComponent,
        DeletedUserComponent,
        AdminListComponent,
        BrandsComponent,
        ModelsComponent,
        AddAdminComponent,
        RemoveAdminComponent,
        AdminLogComponent,
        AdDetailComponent,
        AdListUserComponent,
        AdsComponent,
        RedirectAdComponent,
    ],

    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        NgxSpinnerModule,
        HttpClientModule,
        BrowserAnimationsModule, // required animations module
        ToastrModule.forRoot(),
    ],
    providers: [HttpClient, NgxSpinnerService],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],

    bootstrap: [AppComponent]
})
export class AppModule { }
