import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/user.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

    constructor(private userService: UserService, private router: Router) { }

    ngOnInit(): void {

    }

    routMe(path: any) {
        console.log(path)
        this.userService.checkIfUserLoggedIn(1).then((data: any) => {
            if (!data)
                this.router.navigateByUrl("appadmin/login");
            else
                this.router.navigateByUrl(path);
        }, (error) => {
            this.router.navigateByUrl("appadmin/login");
        })
    }

}
