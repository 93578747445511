<div id="layoutSidenav_nav">
    <nav class="sb-sidenav accordion sb-sidenav-light" id="sidenavAccordion">
        <div class="sb-sidenav-menu">
            <div class="nav">
                <div class="sb-sidenav-menu-heading">List Ads</div>
                <a class="nav-link" (click)="routMe('/appadmin/pending-listings')" routerLinkActive="active">
                    <div class="sb-nav-link-icon"><i class="fas fa-clock"></i></div>
                    Pending
                </a>
                <a class="nav-link" (click)="routMe('/appadmin/approved-ads')" routerLinkActive="active">
                    <div class="sb-nav-link-icon"><i class="fas fa-check-circle"></i></div>
                    Approved
                </a>
                <a class="nav-link" (click)="routMe('/appadmin/paused-ads')" routerLinkActive="active">
                    <div class="sb-nav-link-icon"><i class="fas fa-pause-circle"></i></div>
                    Paused
                </a>
                <a class="nav-link" (click)="routMe('/appadmin/declined-ads')" routerLinkActive="active">
                    <div class="sb-nav-link-icon"><i class="fas fa-ban"></i></div>
                    Declined
                </a>
                <a class="nav-link" (click)="routMe('/appadmin/deleted-ads')" routerLinkActive="active">
                    <div class="sb-nav-link-icon"><i class="fas fa-trash-alt"></i></div>
                    Deleted
                </a>
                <div class="sb-sidenav-menu-heading">List Users</div>
                <a class="nav-link" (click)="routMe('/appadmin/verified-users')" routerLinkActive="active">
                    <div class="sb-nav-link-icon"><i class="fas fa-user-check"></i></div>
                    Verified
                </a>
                <a class="nav-link" (click)="routMe('/appadmin/unverified-users')" routerLinkActive="active">
                    <div class="sb-nav-link-icon"><i class="fas fa-user-times"></i></div>
                    Un-verified
                </a>
                <a class="nav-link" (click)="routMe('/appadmin/deleted-users')" routerLinkActive="active">
                    <div class="sb-nav-link-icon"><i class="fas fa-user-slash"></i></div>
                    Deleted Users
                </a>
                <div class="sb-sidenav-menu-heading">Admob Ads Settings </div>
                <a class="nav-link" (click)="routMe('/appadmin/admob')" routerLinkActive="active">
                    <div class="sb-nav-link-icon"><i class="fas fa-copyright"></i></div>
                    Ads
                </a>
                <!-- 
                    <div class="sb-sidenav-menu-heading">List Brands</div>
                <a class="nav-link" (click)="routMe('/appadmin/brands')" routerLinkActive="active">
                    <div class="sb-nav-link-icon"><i class="fas fa-copyright"></i></div>
                    Brands
                </a>
                <a class="nav-link" (click)="routMe('/appadmin/models')" routerLinkActive="active">
                    <div class="sb-nav-link-icon"><i class="fas fa-layer-group"></i></div>
                    Models
                </a>
                <div class="sb-sidenav-menu-heading">List Admins</div>
                <a class="nav-link" (click)="routMe('/appadmin/admin-list')" routerLinkActive="active">
                    <div class="sb-nav-link-icon"><i class="fas fa-list-ul"></i></div>
                    List all
                </a>
                <a class="nav-link" (click)="routMe('/appadmin/add-admin')" routerLinkActive="active">
                    <div class="sb-nav-link-icon"><i class="fas fa-user-plus"></i></div>
                    Add admin
                </a>
                <a class="nav-link" (click)="routMe('/appadmin/remove-admin')" routerLinkActive="active">
                    <div class="sb-nav-link-icon"><i class="fas fa-user-minus"></i></div>
                    Remove admin
                </a>
                <div class="sb-sidenav-menu-heading">Logs</div>
                <a class="nav-link" (click)="routMe('/appadmin/admin-log')" routerLinkActive="active">
                    <div class="sb-nav-link-icon"><i class="fas fa-clipboard-check"></i></div>
                    Admin Logs
                </a>
            -->
            </div>
        </div>
    </nav>
</div>