import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-redirect-ad',
    templateUrl: './redirect-ad.component.html',
    styleUrls: ['./redirect-ad.component.css']
})
export class RedirectAdComponent implements OnInit {

    constructor(public router: Router, private activeRoute: ActivatedRoute) { }

    ngOnInit(): void {
        console.log(this.activeRoute.url)
        window.open("http://market.android.com/details?id=in.carsbikes.app/", "_blank");
 }

}
