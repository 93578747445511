import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-remove-admin',
  templateUrl: './remove-admin.component.html',
  styleUrls: ['./remove-admin.component.css']
})
export class RemoveAdminComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
