<div id="layoutSidenav_content">
    <main>
        <div class="container-fluid px-4">
            <div class="page-head mt-3 text-center">Ads Settings</div>
            <div class="add-admin-block">

                <form #f="ngForm"  (ngSubmit)="onSubmit(f)" class="form-wd">
                    <div class="form-group row">
                        <label for="staticEmail" class="col-sm-6 col-form-label">Banner Ad</label>
                        <div class="col-sm-6">
                            <label class="switch">
                                <input type="checkbox"  name="showBanner" [ngModel]="showBanner"
                                    (change)="changeStatus(1,$event)">
                                <span class="slider round"></span>
                            </label>
                            <i class="status-txt"> {{showBanner?"SHOW":"HIDE"}}</i>
                        </div>
                    </div>


                    <div class="form-group row">
                        <label for="staticEmail" class="col-sm-6 col-form-label">Interstitial Ads</label>
                        <div class="col-sm-6">
                            <label class="switch">
                                <input type="checkbox" name="showInterstitial" 
                                    [ngModel]="showInterstitial" (change)="changeStatus(2,$event)">

                                <span class="slider round"></span>
                            </label>
                            <i class="status-txt"> {{showInterstitial?"SHOW":"HIDE"}}</i>
                        </div>
                    </div>




                <div class="col mt-3">
                    <label for="formGroupExampleInput" class="form-label">interstial start count</label>
                    <input type="number" class="form-control" id="formGroupExampleInput" placeholder=""
                        name="startCount" [ngModel]="startCount">
                </div>
                <div class="col mt-3">
                    <label for="formGroupExampleInput2" class="form-label">interstial offset count</label>
                    <input type="number" class="form-control" id="formGroupExampleInput2" placeholder=""
                        name="offsetCount" [ngModel]="offsetCount">
                </div>

                <div class="col mt-3">
                    <label for="formGroupExampleInput2" class="form-label">interstial max count</label>
                    <input type="number" class="form-control" id="formGroupExampleInput2" placeholder="" name="maxCount"
                        [ngModel]="maxCount">
                </div>

                <div class="col mt-3">
                    <div class="action-box">
                        <button type="submit"  class="btn btn-dark-prime">Submit</button>
                    </div>
                </div>

            </form>
            </div>
        </div>
    </main>

</div>