import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { EventsService } from 'src/app/events.service';
import { UserService } from 'src/app/user.service';

@Component({
    selector: 'app-ad-list-user',
    templateUrl: './ad-list-user.component.html',
    styleUrls: ['./ad-list-user.component.css']
})
export class AdListUserComponent implements OnInit {

    items: any = [];
    pages: any = [];
    pageItem: any = [];
    currentPage = 1;
    pageMaxItems = 5;
    constructor(private userService: UserService, private router: Router,
        private event: EventsService,
    ) {
        if (this.router.url.indexOf("/appadmin") != -1) {
            this.event.publish('isAdmin', true);
        }
    }

    ngOnInit(): void {
        debugger
        this.userService.checkIfUserLoggedIn(1).then((data) => {
            if (data) {
                this.event.publish('loggedIn', true);
                this.router.navigateByUrl("appadmin/pending-listings");
            }
        }, (er) => {
            console.log(er)
        })
        this.getSession();
        if (this.items.length == 0)
            this.pending(false);
    }

    setSession() {
        sessionStorage.setItem("itemsAdUsers", JSON.stringify(this.items))
        sessionStorage.setItem("pagesAdUsers", JSON.stringify(this.pages))
        sessionStorage.setItem("pageItemAdUsers", JSON.stringify(this.pageItem))
        sessionStorage.setItem("currentPageAdUsers", this.currentPage + "")
    }

    getSession() {
        if (sessionStorage.getItem("itemsAdUsers"))
            this.items = JSON.parse(sessionStorage.getItem("itemsAdUsers"));

        if (sessionStorage.getItem("pagesPendingAdUsers"))
            this.pages = JSON.parse(sessionStorage.getItem("pagesAdUsers"));

        if (sessionStorage.getItem("pageItemAdUsers"))
            this.pageItem = JSON.parse(sessionStorage.getItem("pageItemAdUsers"));

        if (sessionStorage.getItem("currentPageAdUsers"))
            this.currentPage = Number(sessionStorage.getItem("currentPageAdUsers"));
        if (this.items.length == 0)
            this.pages = []
        else
            for (let i = 0; i < (this.items.length / this.pageMaxItems); i++)
                this.pages.push(i + 1);
        this.fillPage();
    }

    ngOnDestroy() {
        console.log("page destroyed");
    }

    pending(val: any) {
        let json: any = { action: 'getVerified', "lastPost": this.items.length > 0 ? this.items[this.items.length - 1] : null };
        this.userService.getSessionIdToken().then((token: any) => {
            if (token) {
                json.token = token;
                this.userService.presentLoading();
                this.userService.sendPostRequest("adminAdsOps", json).then((data: any) => {
                    console.log(data)
                    if (data.status == "ok") {
                        if (data.data && data.data.length == 0)
                            this.userService.presentToast("No Data found")
                        this.items = this.items.concat(data.data);
                        this.pages = [];
                        if (this.items.length == 0)
                            this.pages = [1]
                        else
                            for (let i = 0; i < (this.items.length / this.pageMaxItems); i++)
                                this.pages.push(i + 1);
                        this.fillPage();
                        this.setSession();
                    } else {
                        this.userService.presentToast(data.msg)
                    }
                    this.userService.stopLoading();
                }, (er) => {
                    console.log(er)
                    this.userService.stopLoading();
                });
            }
        })
    }

    fillPage() {
        debugger
        let startIndex = (this.currentPage - 1) * this.pageMaxItems;
        let lastIndex = this.currentPage * this.pageMaxItems < this.items.length ?
            this.pageMaxItems * this.currentPage : this.items.length;
        this.pageItem = this.items.slice(startIndex, lastIndex);
        console.log(this.pageItem)
        console.log(this.items)
    }


    next() {
        //    if(this,this.currentPage)
        if (this.currentPage < this.pages.length) {
            this.currentPage++;
            this.fillPage();
        }
        else {
            //get more;
        }
    }

    previous() {
        if (this.currentPage > 1) {
            this.currentPage--;
            this.fillPage();
        }
    }

    current(page: any) {
        debugger
        this.currentPage = page;
        this.fillPage();
        console.log(page)
    }

    loadMore() {
        this.pending(true)
    }

    details(item: any) {
        console.log(item)
        let navigationExtras: NavigationExtras = {
            queryParams: {
                item: JSON.stringify(item),
            },
        };
        this.router.navigate(['appadmin/ad', item.postId], navigationExtras)
    }
}
