<div id="layoutSidenav_content">
  <main>
      <div class="container-fluid px-4">
          <h1 class="cat-head">Admin Logs</h1>
          <div class="page-head">15 Logs
              <div class="filter-block">
                  <label>Filter by:</label>
                  <div class="form-box">
                      <select class="form-select">
                          <option selected>Select Admin</option>
                          <option value="1">Admin 1</option>
                          <option value="2">Admin 2</option>
                      </select></div>
                  <div class="form-box"><input type="date" class="form-control" placeholder="Date"></div>
                  <div class="form-box">
                      <select class="form-select">
                          <option selected>Select Action</option>>
                          <option value="1">Approve Ads</option>>
                          <option value="2">Verify User</option>>
                      </select>
                  </div>
              </div>
          </div>
          <div class="table-box">
              <table class="table-style table-striped">
                  <thead>
                      <tr>
                          <th>Admin Id</th>
                          <th>Admin name</th>
                          <th>Log</th>
                          <th>Created on</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr>
                          <td>231</td>
                          <td>Amit Kumar</td>
                          <td><strong>User approved</strong>, User id: <strong>10</strong></td>
                          <td>20-05-2021</td>
                      </tr>
                      <tr>
                          <td>231</td>
                          <td>Amit Kumar</td>
                          <td><strong>Ad approved</strong>, Add id: <strong>20</strong></td>
                          <td>20-05-2021</td>
                      </tr>
                      <tr>
                          <td>231</td>
                          <td>Amit Kumar</td>
                          <td><strong>User approved</strong>, User id: <strong>10</strong></td>
                          <td>20-05-2021</td>
                      </tr>
                      <tr>
                          <td>231</td>
                          <td>Amit Kumar</td>
                          <td><strong>Ad approved</strong>, Add id: <strong>20</strong></td>
                          <td>20-05-2021</td>
                      </tr>
                      <tr>
                          <td>231</td>
                          <td>Amit Kumar</td>
                          <td><strong>User approved</strong>, User id: <strong>10</strong></td>
                          <td>20-05-2021</td>
                      </tr>
                      <tr>
                          <td>231</td>
                          <td>Amit Kumar</td>
                          <td><strong>Ad approved</strong>, Add id: <strong>20</strong></td>
                          <td>20-05-2021</td>
                      </tr>
                      <tr>
                          <td>231</td>
                          <td>Amit Kumar</td>
                          <td><strong>User approved</strong>, User id: <strong>10</strong></td>
                          <td>20-05-2021</td>
                      </tr>
                      <tr>
                          <td>231</td>
                          <td>Amit Kumar</td>
                          <td><strong>Ad approved</strong>, Add id: <strong>20</strong></td>
                          <td>20-05-2021</td>
                      </tr>
                      <tr>
                          <td>231</td>
                          <td>Amit Kumar</td>
                          <td><strong>User approved</strong>, User id: <strong>10</strong></td>
                          <td>20-05-2021</td>
                      </tr>
                      <tr>
                          <td>231</td>
                          <td>Amit Kumar</td>
                          <td><strong>Ad approved</strong>, Add id: <strong>20</strong></td>
                          <td>20-05-2021</td>
                      </tr>
                  </tbody>
              </table>
          </div>
      </div>
  </main>

</div>
