import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PendingListingsComponent } from './components/pending-listings/pending-listings.component';
import { ApprovedAdsComponent } from './components/approved-ads/approved-ads.component';
import { PausedAdsComponent } from './components/paused-ads/paused-ads.component';
import { DeclinedAdsComponent } from './components/declined-ads/declined-ads.component';
import { DeletedAdsComponent } from './components/deleted-ads/deleted-ads.component';
import { VerifiedUserComponent } from './components/verified-user/verified-user.component';
import { UnverifiedUserComponent } from './components/unverified-user/unverified-user.component';
import { DeletedUserComponent } from './components/deleted-user/deleted-user.component';
import { AdminListComponent } from './components/admin-list/admin-list.component';
import { BrandsComponent } from './components/brands/brands.component';
import { ModelsComponent } from './components/models/models.component';
import { AddAdminComponent } from './components/add-admin/add-admin.component';
import { RemoveAdminComponent } from './components/remove-admin/remove-admin.component';
import { AdminLogComponent } from './components/admin-log/admin-log.component';
import { AdDetailComponent } from './components/ad-detail/ad-detail.component';
import { AdListUserComponent } from './components/ad-list-user/ad-list-user.component';
import { LoginComponent } from './components/login/login.component';
import { AdsComponent } from './components/ads/ads/ads.component';
import { RedirectAdComponent } from './components/redirect-ad/redirect-ad.component';

const routes: Routes = [
    { path: '', component: RedirectAdComponent },
    { path: 'ad/:adId', component: RedirectAdComponent },

    {
        path: 'appadmin',
        children: [{
            path: 'ad/:adId',
            component: AdDetailComponent
        }, {
            path: 'pending-listings',
            component: PendingListingsComponent,
        },
        { path: 'login', component: LoginComponent },
        { path: 'approved-ads', component: ApprovedAdsComponent },
        { path: 'paused-ads', component: PausedAdsComponent },
        { path: 'declined-ads', component: DeclinedAdsComponent },
        { path: 'deleted-ads', component: DeletedAdsComponent },
        { path: 'verified-users', component: VerifiedUserComponent },
        { path: 'unverified-users', component: UnverifiedUserComponent },
        { path: 'admob', component: AdsComponent },
        { path: 'deleted-users', component: DeletedUserComponent },
        { path: 'admin-list', component: AdminListComponent },
        { path: 'brands', component: BrandsComponent },
        { path: 'models', component: ModelsComponent },
        { path: 'add-admin', component: AddAdminComponent },
        { path: 'remove-admin', component: RemoveAdminComponent },
        { path: 'admin-log', component: AdminLogComponent },
        { path: 'ad-list-user', component: AdListUserComponent },
        { path: '', component: LoginComponent },
        { path: '**', redirectTo: '', pathMatch: 'full' },
        ]

    },

    { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
