import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { EventsService } from 'src/app/events.service';
import { UserService } from 'src/app/user.service';

@Component({
    selector: 'app-deleted-ads',
    templateUrl: './deleted-ads.component.html',
    styleUrls: ['./deleted-ads.component.css']
})
export class DeletedAdsComponent implements OnInit {
    items: any = [];
    pages: any = [];
    pageItem: any = [];
    currentPage = 1;
    pageMaxItems = 5;
    constructor(private userService: UserService, private router: Router,
        private event: EventsService,
    ) {
        if (this.router.url.indexOf("/appadmin") != -1) {
            this.event.publish('isAdmin', true);
        }
    }

    ngOnInit(): void {
        // this.getSession();

        this.userService.checkIfUserLoggedIn(1).then((data) => {
            if (data) {
                this.event.publish('loggedIn', true);
                if (this.items.length == 0) {
                    let json: any = { action: 'getDeleted', "lastPost": this.items.length > 0 ? this.items[this.items.length - 1] : null };

                    this.pending(json)
                }
            } else {
                this.router.navigateByUrl("appadmin/login");

            }
        }, (er) => {
            console.log(er)
            this.router.navigateByUrl("appadmin/login");

        })

    }
    resetVars() {
        this.items = [];
        this.pages = [];
        this.pageItem = [];
        this.currentPage = 1;
    }

    search() {
        let idMobLoc: any = document.getElementById("idMobLoc");
        let fromDate: any = document.getElementById("fromDate");
        let toDate: any = document.getElementById("toDate");
        console.log(idMobLoc.value)
        console.log(fromDate.value)
        console.log(toDate.value)
        let val = this.userService.checkIsMobLocOrId(idMobLoc.value);
        console.log(val);
        if (val == "id" || val == "location") {
            this.resetVars();
            let json: any = {
                action: 'getDeleted', search: val,
                "lastPost": this.items.length > 0 ? this.items[this.items.length - 1] : null
            };
            if (val == "id")
                json.id = idMobLoc.value.trim();
            else
                json.location = idMobLoc.value.trim();
            if (fromDate.value.length > 0) {
                json.fromDate = new Date(fromDate.value).getTime();
            }

            if (toDate.value.length > 0) {
                json.toDate = new Date(toDate.value).getTime();
            }
            this.pending(json);
        } else {
            this.userService.presentToast("Enter user Id or location.");
        }
    }

    setSession() {
        sessionStorage.setItem("itemsDeleted", JSON.stringify(this.items))
        sessionStorage.setItem("pagesDeleted", JSON.stringify(this.pages))
        sessionStorage.setItem("pageItemDeleted", JSON.stringify(this.pageItem))
        sessionStorage.setItem("currentPageDeleted", this.currentPage + "")
    }

    getSession() {
        if (sessionStorage.getItem("itemsDeleted"))
            this.items = JSON.parse(sessionStorage.getItem("itemsDeleted"));

        if (sessionStorage.getItem("pagesPendingDeleted"))
            this.pages = JSON.parse(sessionStorage.getItem("pagesDeleted"));

        if (sessionStorage.getItem("pageItemDeleted"))
            this.pageItem = JSON.parse(sessionStorage.getItem("pageItemDeleted"));

        if (sessionStorage.getItem("currentPageDeleted"))
            this.currentPage = Number(sessionStorage.getItem("currentPageDeleted"));
        if (this.items.length == 0)
            this.pages = []
        else
            for (let i = 0; i < (this.items.length / this.pageMaxItems); i++)
                this.pages.push(i + 1);
        this.fillPage();
    }

    ngOnDestroy() {
        console.log("page destroyed");
    }

    pending(json: any) {
        this.userService.getSessionIdToken().then((token: any) => {
            if (token) {
                json.token = token;
                this.userService.presentLoading();
                this.userService.sendPostRequest("adminAdsOps", json).then((data: any) => {
                    console.log(data)
                    if (data.status == "ok") {
                        if (data.data && data.data.length == 0)
                            this.userService.presentToast("No Data found")
                        this.items = this.items.concat(data.data);
                        this.pages = [];
                        if (this.items.length == 0)
                            this.pages = [1]
                        else
                            for (let i = 0; i < (this.items.length / this.pageMaxItems); i++)
                                this.pages.push(i + 1);
                        this.fillPage();
                        this.setSession();
                    } else {
                        this.userService.presentToast(data.msg)
                    }
                    this.userService.stopLoading();
                }, (er) => {
                    console.log(er)
                    this.userService.stopLoading();
                });
            }
        })
    }

    fillPage() {
        debugger
        let startIndex = (this.currentPage - 1) * this.pageMaxItems;
        let lastIndex = this.currentPage * this.pageMaxItems < this.items.length ?
            this.pageMaxItems * this.currentPage : this.items.length;
        this.pageItem = this.items.slice(startIndex, lastIndex);
        console.log(this.pageItem)
        console.log(this.items)
    }


    next() {
        //    if(this,this.currentPage)
        if (this.currentPage < this.pages.length) {
            this.currentPage++;
            this.fillPage();
        }
        else {
            //get more;
        }
    }

    previous() {
        if (this.currentPage > 1) {
            this.currentPage--;
            this.fillPage();
        }
    }

    current(page: any) {
        debugger
        this.currentPage = page;
        this.fillPage();
        console.log(page)
    }

    loadMore() {
        let json: any = { action: 'getDeleted', "lastPost": this.items.length > 0 ? this.items[this.items.length - 1] : null };

        this.pending(json)
    }

    details(item: any) {
        console.log(item)
        let navigationExtras: NavigationExtras = {
            queryParams: {
                item: JSON.stringify(item),
            },
        };
        this.router.navigate(['appadmin/ad', item.postId], navigationExtras)
    }
}

