<div id="layoutSidenav_content">
    <main>
        <h2 *ngIf="noData" style="text-align: center;width: 100%; margin-top: 30%;"> No Ads Found for the user </h2>
        <div *ngIf="detail" class="container-fluid px-4">
            <h2 class="page-head mt-3"><a href="pending-ads.html"><i class="fas fa-arrow-left"></i></a> Product Details
            </h2>
            <div class="ad-detail-block">
                <div class="title-block">
                    {{detail?.title}}
                    <span class="meta"><strong>Posted on:</strong> {{detail?.publishDate| date:'dd-MM-yyyy'}}</span>
                </div>

                <div class="gallery-block">
                    <ul class="list-inline">
                        <li *ngFor="let img of detail?.images" class="list-inline-item"><img src="{{img.url}}"></li>
                    </ul>
                </div>

                <div class="info-list">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item">
                            <label>Price:</label>
                            <div class="info-box"><span>&#8377;
                                </span>{{detail?.price}}</div>
                        </li>
                        <li class="list-group-item">
                            <label>Brand:</label>
                            <div class="info-box">{{detail?.brand}}</div>
                        </li>
                        <li class="list-group-item">
                            <label>Model:</label>
                            <div class="info-box">{{detail?.model}}</div>
                        </li>
                        <li class="list-group-item">
                            <label>Year:</label>
                            <div class="info-box">{{detail?.year}}</div>
                        </li>
                        <li class="list-group-item">
                            <label>Fuel:</label>
                            <div class="info-box">{{detail?.engine}}</div>
                        </li>
                        <li class="list-group-item">
                            <label>Transmission:</label>
                            <div class="info-box">{{detail?.transmission}}</div>
                        </li>
                        <li class="list-group-item">
                            <label>KM driven:</label>
                            <div class="info-box">{{detail?.kmsDriven}}</div>
                        </li>
                        <li class="list-group-item">
                            <label>Posted by:</label>
                            <div class="info-box"><a href="#">{{detail?.userDoc?.name}}</a></div>
                        </li>
                        <li class="list-group-item">
                            <label>Description:</label>
                            <div class="info-box">{{detail?.description}}
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="decision-box">
                    <div class="action-box">
                        <a *ngIf="detail?.status=='added'" (click)="approve(true)" class="btn btn-success">Approve</a>
                        <a *ngIf="detail?.status=='added'" (click)=" approve(false)" class="btn btn-danger">Decline</a>
                    </div>
                </div>
            </div>
            <div class="other-app-block">
                <div class="header">Other ads by user</div>
                <div class="list-ad-box">

                    <div *ngFor="let item of items" class="ad-box" (click)="details(item)">
                        <img src="{{item?.images[0]?.iconUrl}}">
                        <h3>{{item.title}}</h3>
                        <span class="meta"><strong>Posted on:</strong> {{detail?.publishDate|
                            date:'dd-MM-yyyy'}}</span>
                    </div>

                    <!-- <div class="ad-box">
                        <img src="assets/img/gall-01.png" alt="">
                        <h3>Ford Figo 1.5L Diesel Titanium M</h3>
                        <span class="meta"><strong>Posted on:</strong> 20-05-2021</span>
                    </div>
                    <div class="ad-box">
                        <img src="assets/img/gall-01.png" alt="">
                        <h3>Ford Figo 1.5L Diesel Titanium M</h3>
                        <span class="meta"><strong>Posted on:</strong> 20-05-2021</span>
                    </div>
                    <div class="ad-box">
                        <img src="assets/img/gall-01.png" alt="">
                        <h3>Ford Figo 1.5L Diesel Titanium M</h3>
                        <span class="meta"><strong>Posted on:</strong> 20-05-2021</span>
                    </div>
                    <div class="ad-box">
                        <img src="assets/img/gall-01.png" alt="">
                        <h3>Ford Figo 1.5L Diesel Titanium M</h3>
                        <span class="meta"><strong>Posted on:</strong> 20-05-2021</span>
                    </div> -->
                </div>
            </div>
        </div>
    </main>
</div>