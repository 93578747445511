<div id="layoutSidenav_content">
    <main>
        <div class="container-fluid px-4">
            <h1 class="cat-head">List Ads</h1>
            <div class="page-head">30 Declined Ads
                <div class="filter-block">
                    <label class="apply-but"
                        (click)=" search()">Apply</label>
                    <label>Filter by:</label>
                    <div class="form-box big-input"><input type="text" id="idMobLoc" class="form-control"
                            placeholder="Search by User Id, Location">
                    </div>
                    <div class="form-box"><span>From:</span>
                        <input id="fromDate" type="date" class="form-control" placeholder="Date">
                    </div>
                    <div class="form-box"><span>To:</span>
                        <input id="toDate" type="date" class="form-control" placeholder="Date">
                    </div>
                </div>
            </div>
            <div class="table-box">
                <table class="table-style pending-table table">
                    <thead>
                        <tr>
                            <th><input type="checkbox" name=""></th>
                            <th>Id</th>
                            <th>Product Image</th>
                            <th>Title</th>
                            <th>Location</th>
                            <th>Posted by</th>
                            <th>Declined on</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of pageItem;let i=index">
                            <td><input type="checkbox" name=""></td>
                            <td>{{item.postId}}</td>
                            <td><img src="{{item.images[0].iconUrl}}" alt="" class="tr-img"></td>
                            <td>{{item.title}}</td>
                            <td>{{item.location}}</td>
                            <td>{{item.userDoc.name}}</td>
                            <td>{{item.publishDate | date:'dd-MM-yyyy'}}</td>
                            <td><a class="btn btn-outlined-dark" (click)="details(item)">Details</a></td>
                            <td><a class="btn btn-success" (click)="approve(item)">Reapprove</a></td>
                        </tr>
                    <tbody>
                </table>
                <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-end">
                        <li class="page-item" *ngIf="this.currentPage>1">
                            <a class="page-link" (click)="previous()" tabindex="-1">Previous</a>
                        </li>
                        <li *ngFor="let page of pages" class="page-item"><a
                                [ngClass]="{'highlightPage': this.currentPage == page, 'page-link':true}"
                                (click)="current(page)">{{page}}</a></li>
                        <li class="page-item" *ngIf="this.currentPage != pages.length">
                            <a class="page-link" (click)="next()">Next</a>
                        </li>
                        <li class="page-item" *ngIf="this.currentPage == pages.length">
                            <a class="page-link" (click)="loadMore()">Load More..</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </main>

</div>