import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { v4 as uuidv4 } from "uuid";
import { Router, NavigationExtras } from "@angular/router";
import firebase from "firebase/app";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';


import 'firebase/firestore'
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/database';



declare var admob;

@Injectable({
    providedIn: "root",
})
export class UserService {
    baseUrl = "https://autos4india.web.app/api/";
    db: any;
    constructor(
        private toastr: ToastrService,
        private http: HttpClient,
        private router: Router, private spinner: NgxSpinnerService
    ) {
        const firebaseConfig = {
            apiKey: "AIzaSyARxAXCXnWr63f6wEqwZMqj64KwUwUIRHA",
            authDomain: "autos4india.firebaseapp.com",
            projectId: "autos4india",
            storageBucket: "autos4india.appspot.com",
            messagingSenderId: "1062806664217",
            appId: "1:1062806664217:web:0d434582a46cef15e0a688",
            measurementId: "G-65PJREVG1C"
        };

        firebase.initializeApp(firebaseConfig);

        let ref = this;
        firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
                console.log("create presence");
                this.uid = firebase.auth().currentUser.uid;
            } else {
                // No user is signed in.
            }
        });


    }

    checkIfUserLoggedIn(type: number) {
        let ref = this;
        return new Promise(function (resolve, reject) {
            firebase?.auth()?.onAuthStateChanged(function (user) {
                debugger
                if (user) {
                    console.log(2, user.uid)
                    console.log(3, user)
                    if (type == 1)
                        resolve(true);
                    else
                        resolve(user.uid);
                } else {
                    // No user is signed in.
                    console.log(4, user)
                    if (type == 1)
                        resolve(false);
                    else
                        resolve(0);
                }
            }, error => {
                if (type == 1)
                    reject(0);
                else
                    reject(0);
                console.log(5)

            });
        });

    }

    uid: any
    getUid() {
        this.uid = firebase.auth().currentUser.uid;
        return this.uid;
    }

    sendPostRequest(api: any, postData: any) {
        let url = this.baseUrl + api;
        let ref = this;
        var headers = new HttpHeaders();
        headers.append("Origin", "http://localhost:8100/");
        headers.append("Content-Type", "application/json");
        var promise = new Promise(function (resolve, reject) {
            ref.http.post(url, postData, { headers: headers }).subscribe(
                (data) => {
                    resolve(data);
                },
                (error) => {
                    reject(error);
                    console.log(error);
                }
            );
        });
        return promise;
    }

    signInWithCustomToken(token: any) {
        return new Promise(function (resolve, reject) {
            firebase.auth()?.signInWithCustomToken(token)
                .then((userCredential) => {
                    resolve(userCredential);
                })
                .catch((error) => {
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    reject(error);
                }) || setTimeout(() => {
                    firebase.auth().signInWithCustomToken(token)
                        .then((userCredential) => {
                            resolve(userCredential);
                        })
                        .catch((error) => {
                            var errorCode = error.code;
                            var errorMessage = error.message;
                            reject(error);
                        })
                }, 250);
        })
    }

    firebaseSignOut() {
        let ref = this;
        return new Promise(function (resolve, reject) {
            firebase.auth().signOut().then((data: any) => {
                resolve(data)
            }, (err) => {
                console.log(err)
                reject();
            })

        });
    }

    getSessionIdToken() {
        return new Promise(function (resolve, reject) {
            debugger
            firebase.auth().currentUser?.getIdToken(/* forceRefresh */ true).then(function (idToken) {
                resolve(idToken);
            }).catch(function (error) {
                reject(error);
            }) || setTimeout(() => {
                debugger
                firebase.auth().currentUser?.getIdToken(/* forceRefresh */ true).then(function (idToken) {
                    resolve(idToken);
                }).catch(function (error) {
                    reject(error);
                })
            }, 1000);
        });
    }



    uploadFile(file: any, type: any) {
        if (type == 1) {
            const storageRef = firebase.storage().ref();

            return storageRef.child('images/' + uuidv4() + ".jpeg").put(file);
        } else {
            return new Promise(function (resolve, reject) {
                resolve({ metadata: { fullPath: file }, type: 0 });
            });
        }
    }

    getDownloadUrl(path: any, type: any) {
        if (type == 1) {
            const storageRef = firebase.storage().ref();
            var starsRef = storageRef.child(path);
            return starsRef.getDownloadURL()
        } else {
            return new Promise(function (resolve, reject) {
                resolve(path);
            });
        }
    }

    presentLoading() {
        this.spinner.show().then((data) => {
            console.log(data)
        }, (err) => {
            console.log(err)
        });
    }

    stopLoading() {
        this.spinner.hide().then((data) => {
            console.log(data)
        }, (err) => {
            console.log(err)
        })
    }

    presentToast(msg: any) {
        this.toastr.success(msg);
    }

    checkIsMobLocOrId(data: any) {
        data = data.trim();
        if (data.length < 2)
            return "invalid";
        else if (/^\d{10}$/.test(data))
            return "mobile";
        else if (/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(data))
            return "id";
        else if (!/[^a-zA-Z ]/.test(data))
            return "location";
        else
            return "invalid";
    }

}
