import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventsService } from 'src/app/events.service';
import { UserService } from 'src/app/user.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.css']
})
export class TopbarComponent implements OnInit {
    isLoggedIn: Boolean = false;

    constructor(private userService: UserService, private event: EventsService,
        private router: Router) { }

    ngOnInit(): void {

        this.event.subscribe("loggedIn", (data) => {
            console.log(data);
            this.isLoggedIn = data;
        })


    }

    logout() {
        this.userService.firebaseSignOut().then((data) => {
            this.isLoggedIn = false;
            this.event.publish('loggedIn', false);
            this.router.navigateByUrl('/appadmin/login');


        })
    }

    login() {
        this.router.navigateByUrl('/appadmin/login');
    }
}
