import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common'
import { UserService } from 'src/app/user.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { EventsService } from 'src/app/events.service';

@Component({
    selector: 'app-ads',
    templateUrl: './ads.component.html',
    styleUrls: ['./ads.component.css']
})
export class AdsComponent implements OnInit {
    showInterstitial: boolean;
    showBanner: boolean = true;
    startCount: number;
    offsetCount: number;
    maxCount: number;
    interstitialAds: any;
    bannerAds: any;

    constructor(private location: Location, private router: Router,
        private event: EventsService,
        private userService: UserService) {
        if (this.router.url.indexOf("/appadmin") != -1) {
            this.event.publish('isAdmin', true);
        }
    }

    ngOnInit(): void {
        this.userService.checkIfUserLoggedIn(1).then((data) => {
            if (data) {
                this.event.publish('loggedIn', true);
                this.getSetAdsData(false, {});

            }else{
                this.router.navigateByUrl("appadmin/login");

            }
        }, (er) => {
            console.log(er)
            this.router.navigateByUrl("appadmin/login");

        })
    }

    back(): void {
        this.location.back()
    }

    changeStatus(type: any, event: any) {
        console.log(event.target.checked)
        if (type == 1) {
            this.showBanner = event.target.checked;
        } else {
            this.showInterstitial = event.target.checked;
        }
    }

    getSetAdsData(val: boolean, json: any) {
        this.userService.presentLoading();
        json.action = val ? 'setAdmobData' : 'getAdmobData';
        this.userService.getSessionIdToken().then((token: any) => {
            if (token) {
                json.token = token;
                this.userService.sendPostRequest("adminAdsOps", json).then((data: any) => {
                    console.log(data)
                    if (data.status == "ok") {
                        if (val == false) {
                            this.interstitialAds = data.data.interstitialAds;
                            this.bannerAds = data.data.bannerAds;
                            this.maxCount = this.interstitialAds.max;
                            this.offsetCount = this.interstitialAds.offset;
                            this.showInterstitial = this.interstitialAds.show;
                            this.startCount = this.interstitialAds.start;
                            this.showBanner = this.bannerAds.show;
                            this.userService.presentToast('success');
                        }
                        else {
                            this.userService.presentToast('successfully updated');
                        }
                    } else {
                        this.userService.presentToast(data.msg)
                    }
                    this.userService.stopLoading();
                }, (er) => {
                    console.log(er)
                    this.userService.stopLoading();
                });
            } else {
                this.userService.stopLoading();
                this.router.navigateByUrl('appadmin/login')

            }
        }, er => {
            this.userService.stopLoading();
            this.router.navigateByUrl('appadmin/login')
        })
    }

    onSubmit(data: any) {
        let json = data.value;
        if (json.maxCount && json.offsetCount && json.startCount &&
            (json.showInterstitial == true || json.showInterstitial == false)
            && (json.showBanner == true || json.showBanner == false)
        ) {
            this.interstitialAds = {
                max:
                    json.maxCount,
                offset:
                    json.offsetCount,
                show:
                    json.showInterstitial,
                start:
                    json.startCount
            };

            this.bannerAds = {
                show:
                    json.showBanner

            }
            this.getSetAdsData(true, { interstitialAds: this.interstitialAds, bannerAds: this.bannerAds });

        } else {
            this.userService.presentToast("error, please fill all fields")
        }
    }
}
