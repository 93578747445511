<div id="layoutSidenav_content">
  <main>
      <div class="container-fluid px-4">
          <h2 class="page-head mt-3"><a href="verified-user.html"><i class="fas fa-arrow-left"></i></a> 5 Cars listed by Amit Kumar</h2>
          <div class="table-box">
              <table class="table-style">
                  <thead>
                      <tr>
                          <th><input type="checkbox" name =""></th>
                          <th>Id</th>
                          <th>Product Image</th>
                          <th>Title</th>
                          <th>Posted by</th>
                          <th>Created on</th>
                          <th>Approved on</th>
                          <th>Expiring on</th>
                          <th></th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr>
                          <td><input type="checkbox" name =""></td>
                          <td>231</td>
                          <td><img src="assets/img/car-thumb.png" alt=""></td>
                          <td>Mitsubishi Lancer 2.0 L Diesel LX, 2010, Die...</td>
                          <td>Amit Kumar</td>
                          <td>20-05-2021</td>
                          <td>20-05-2021</td>
                          <td>20-05-2021</td>
                          <td><a href="#" class="btn btn-outlined-dark">Details</a></td>
                      </tr>
                      <tr>
                          <td><input type="checkbox" name =""></td>
                          <td>231</td>
                          <td><img src="assets/img/car-thumb.png" alt=""></td>
                          <td>Mitsubishi Lancer 2.0 L Diesel LX, 2010, Die...</td>
                          <td>Amit Kumar</td>
                          <td>20-05-2021</td>
                          <td>20-05-2021</td>
                          <td>Expired</td>
                          <td><a href="#" class="btn btn-outlined-dark">Details</a></td>
                      </tr>
                      <tr>
                          <td><input type="checkbox" name =""></td>
                          <td>231</td>
                          <td><img src="assets/img/car-thumb.png" alt=""></td>
                          <td>Mitsubishi Lancer 2.0 L Diesel LX, 2010, Die...</td>
                          <td>Amit Kumar</td>
                          <td>20-05-2021</td>
                          <td>20-05-2021</td>
                          <td>20-05-2021</td>
                          <td><a href="#" class="btn btn-outlined-dark">Details</a></td>
                      </tr>
                      <tr>
                          <td><input type="checkbox" name =""></td>
                          <td>231</td>
                          <td><img src="assets/img/car-thumb.png" alt=""></td>
                          <td>Mitsubishi Lancer 2.0 L Diesel LX, 2010, Die...</td>
                          <td>Amit Kumar</td>
                          <td>20-05-2021</td>
                          <td>20-05-2021</td>
                          <td>20-05-2021</td>
                          <td><a href="#" class="btn btn-outlined-dark">Details</a></td>
                      </tr>
                      <tr>
                          <td><input type="checkbox" name =""></td>
                          <td>231</td>
                          <td><img src="assets/img/car-thumb.png" alt=""></td>
                          <td>Mitsubishi Lancer 2.0 L Diesel LX, 2010, Die...</td>
                          <td>Amit Kumar</td>
                          <td>20-05-2021</td>
                          <td>20-05-2021</td>
                          <td>20-05-2021</td>
                          <td><a href="#" class="btn btn-outlined-dark">Details</a></td>
                      </tr>
                  </tbody>
              </table>
          </div>
      </div>
  </main>

</div>
