import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { EventsService } from 'src/app/events.service';
import { UserService } from 'src/app/user.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    email: any;
    password: any;
    constructor(private userService: UserService, private router: Router,
        private event: EventsService,
        private spinner: NgxSpinnerService) {
            debugger
       
    }


    ngOnInit(): void {
        this.userService.checkIfUserLoggedIn(1).then((data) => {

            if (data) {

                this.event.publish('loggedIn', true);
                this.router.navigateByUrl("appadmin/pending-listings");
            }
        }, (er) => {
            console.log(er)
        })
    }

    login() {
        this.spinner.show();
        let json = { email: this.email, password: this.password };
        this.userService.sendPostRequest("adminLogin", json).then((data: any) => {
            console.log(data)
            if (data.status == "ok") {
                this.userService.signInWithCustomToken(data.token).then((user) => {
                    console.log(user)
                    this.event.publish('loggedIn', true);
                    this.userService.presentToast(data.msg)
                    this.router.navigateByUrl("appadmin/pending-listings");
                });
            } else {
                this.userService.presentToast(data.msg)
            }
            this.userService.stopLoading();

        }, (er) => {
            console.log(er)
            this.userService.stopLoading();

        });
    }

}
