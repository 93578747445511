<div id="layoutSidenav_content">
  <main>
      <div class="container-fluid px-4">
          <div class="page-head mt-3 text-center">Add Admin</div>
          <div class="add-admin-block">
              <div class="row"></div>
              <div class="col mt-3">
                  <label for="formGroupExampleInput" class="form-label">Enter Name</label>
                  <input type="text" class="form-control" id="formGroupExampleInput" placeholder="">
                </div>
                <div class="col mt-3">
                  <label for="formGroupExampleInput2" class="form-label">Enter Email</label>
                  <input type="text" class="form-control" id="formGroupExampleInput2" placeholder="">
                </div>
                <div class="col mt-3">
                  <label for="formGroupExampleInput2" class="form-label">Select Role</label>
                  <select id="inputState" class="form-select">
                      <option selected>Choose...</option>
                      <option>...</option>
                  </select>
                </div>
                <div class="col mt-3">
                    <div class="action-box">
                        <a href="#" class="btn btn-dark-prime">Add Admin</a>
                    </div>
                </div>
          </div>
      </div>
  </main>

</div>
