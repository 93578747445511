<div id="layoutSidenav_content">
    <main>
        <div class="container-fluid px-4">
            <h1 class="cat-head">List Ads</h1>
            <div class="page-head">30 Paused Ads
                <div class="filter-block">
                    <label class="apply-but" (click)=" search()">Apply</label>
                    <label>Filter by:</label>
                    <div class="form-box big-input"><input type="text" id="idMobLoc3" class="form-control"
                            placeholder="Search by User Id, Location">
                    </div>
                    <div class="form-box"><span>From:</span>
                        <input id="fromDate3" type="date" class="form-control" placeholder="Date">
                    </div>
                    <div class="form-box"><span>To:</span>
                        <input id="toDate3" type="date" class="form-control" placeholder="Date">
                    </div>
                </div>
            </div>
            <div class="table-box">
                <table class="table-style">
                    <thead>
                        <tr>
                            <th><input type="checkbox" name=""></th>
                            <th>Id</th>
                            <th>Product Image</th>
                            <th>Title</th>
                            <th>Posted by</th>
                            <th>Created on</th>
                            <th>Paused on</th>
                            <th>Expiring on</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of pageItem;let i=index">
                            <td><input type="checkbox" name=""></td>
                            <td>{{item.postId}}</td>
                            <td><img src="{{item.images[0].iconUrl}}" alt="" class="tr-img"></td>
                            <td>{{item.title}}</td>
                            <td>{{item.userDoc.name}}</td>
                            <td>{{item.publishDate | date:'dd-MM-yyyy'}}</td>
                            <td>{{item.pauseDate | date:'dd-MM-yyyy'}}</td>
                            <td>{{item.expiryDate | date:'dd-MM-yyyy'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </main>

</div>