<div id="layoutSidenav_content">
  <main>
      <div class="container-fluid px-4">
          <h1 class="cat-head">Admin List</h1>
          <h2 class="page-head">30 Removed Admins</h2>
          <div class="table-box">
              <table class="table-style">
                  <thead>
                      <tr>
                          <th><input type="checkbox" name =""></th>
                          <th>Admin Id</th>
                          <th>Admin name</th>
                          <th>Role</th>
                          <th>Permissions</th>
                          <th>Deleted on</th>
                          <th></th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr>
                          <td><input type="checkbox" name =""></td>
                          <td>231</td>
                          <td>Amit Kumar</td>
                          <td>Contributor</td>
                          <td>Read, Edit</td>
                          <td>20-05-2021</td>
                          <td><a href="#" class="btn btn-success">Add again</a></td>
                      </tr>
                      <tr>
                          <td><input type="checkbox" name =""></td>
                          <td>231</td>
                          <td>Amit Kumar</td>
                          <td>Contributor</td>
                          <td>Read, Edit</td>
                          <td>20-05-2021</td>
                          <td><a href="#" class="btn btn-success">Add again</a></td>
                      </tr>
                      <tr>
                          <td><input type="checkbox" name =""></td>
                          <td>231</td>
                          <td>Amit Kumar</td>
                          <td>Contributor</td>
                          <td>Read, Edit</td>
                          <td>20-05-2021</td>
                          <td><a href="#" class="btn btn-success">Add again</a></td>
                      </tr>
                      <tr>
                          <td><input type="checkbox" name =""></td>
                          <td>231</td>
                          <td>Amit Kumar</td>
                          <td>Contributor</td>
                          <td>Read, Edit</td>
                          <td>20-05-2021</td>
                          <td><a href="#" class="btn btn-success">Add again</a></td>
                      </tr>
                      <tr>
                          <td><input type="checkbox" name =""></td>
                          <td>231</td>
                          <td>Amit Kumar</td>
                          <td>Contributor</td>
                          <td>Read, Edit</td>
                          <td>20-05-2021</td>
                          <td><a href="#" class="btn btn-success">Add again</a></td>
                      </tr>
                  </tbody>
              </table>
          </div>
      </div>
  </main>

</div>
