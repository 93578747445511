<div id="layoutSidenav_content">
  <main>
      <div class="container-fluid px-4">
          <h1 class="cat-head">List Brands</h1>
          <div class="page-head">20 Models
              <div class="filter-block">
                  <label>Filter by:</label>
                  <div class="form-box big-input"><input type="text" class="form-control" placeholder="Search by model name"></div>
                  <div class="form-box">
                      <select class="form-select">
                          <option selected>Select Brand</option>
                          <option value="1">Brand 1</option>
                          <option value="2">Brand 2</option>
                      </select>
                  </div>
              </div>
          </div>
          <div class="table-box">
              <table class="table-style pending-table table table-model">
                  <thead>
                      <tr>
                          <th><input type="checkbox" name =""></th>
                          <th>Id</th>
                          <th>Model</th>
                          <th>Brand Name</th>
                          <th></th>
                          <th></th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr>
                          <td><input type="checkbox" name =""></td>
                          <td>231</td>
                          <td class="model-box">
                              <span id="model-name">Z4 Roadster</span>
                              <input id="model-form" type="text" class="form-control" value="Z4 Roadster">
                          </td>
                          <td class="brand-box">
                              <span id="brand-name">BMW</span>
                              <select class="form-select" id="brand-select">
                                  <option selected>Select Brand</option>
                                  <option>Ford</option>
                                  <option>Honda</option>
                                  <option>Hyundai</option>
                              </select>
                          </td>
                          <td></td>
                          <td class="action-box">
                              <i id="edit-handle-model" class="far fa-edit"></i>
                              <i id="save-handle-model" class="far fa-check-circle"></i>
                              <i class="far fa-trash-alt"></i>
                          </td>
                      </tr>
                      <tr>
                          <td><input type="checkbox" name =""></td>
                          <td>232</td>
                          <td class="model-box">
                              <span id="model-name">Eco Sport</span>
                              <input id="model-form" type="text" class="form-control" value="Eco Sport">
                          </td>
                          <td class="brand-box">
                              <span id="brand-name">Ford</span>
                              <select class="form-select" id="brand-select">
                                  <option selected>Select Brand</option>
                                  <option>Ford</option>
                                  <option>Honda</option>
                                  <option>Hyundai</option>
                              </select>
                          </td>
                          <td></td>
                          <td class="action-box">
                              <i id="edit-handle" class="far fa-edit"></i>
                              <i class="far fa-trash-alt"></i>
                          </td>
                      </tr>
                      <tr>
                          <td><input type="checkbox" name =""></td>
                          <td>233</td>
                          <td class="model-box">
                              <span id="model-name">Jazz</span>
                              <input id="model-form" type="text" class="form-control" value="Jazz">
                          </td>
                          <td class="brand-box">
                              <span id="brand-name">Ford</span>
                              <select class="form-select" id="brand-select">
                                  <option selected>Select Brand</option>
                                  <option>Ford</option>
                                  <option>Honda</option>
                                  <option>Hyundai</option>
                              </select>
                          </td>
                          <td></td>
                          <td class="action-box">
                              <i id="edit-handle" class="far fa-edit"></i>
                              <i class="far fa-trash-alt"></i>
                          </td>
                      </tr>
                      <tr>
                          <td><input type="checkbox" name =""></td>
                          <td>234</td>
                          <td class="model-box">
                              <span id="model-name">Jazz</span>
                              <input id="model-form" type="text" class="form-control" value="Jazz">
                          </td>
                          <td class="brand-box">
                              <span id="brand-name">Ford</span>
                              <select class="form-select" id="brand-select">
                                  <option selected>Select Brand</option>
                                  <option>Ford</option>
                                  <option>Honda</option>
                                  <option>Hyundai</option>
                              </select>
                          </td>
                          <td></td>
                          <td class="action-box">
                              <i id="edit-handle" class="far fa-edit"></i>
                              <i class="far fa-trash-alt"></i>
                          </td>
                      </tr>
                      <tr>
                          <td><input type="checkbox" name =""></td>
                          <td>235</td>
                          <td class="model-box">
                              <span id="model-name">Jazz</span>
                              <input id="model-form" type="text" class="form-control" value="Jazz">
                          </td>
                          <td class="brand-box">
                              <span id="brand-name">Ford</span>
                              <select class="form-select" id="brand-select">
                                  <option selected>Select Brand</option>
                                  <option>Ford</option>
                                  <option>Honda</option>
                                  <option>Hyundai</option>
                              </select>
                          </td>
                          <td></td>
                          <td class="action-box">
                              <i id="edit-handle" class="far fa-edit"></i>
                              <i class="far fa-trash-alt"></i>
                          </td>
                      </tr>
                      <tr>
                          <td><input type="checkbox" name =""></td>
                          <td>236</td>
                          <td class="model-box">
                              <span id="model-name">Jazz</span>
                              <input id="model-form" type="text" class="form-control" value="Jazz">
                          </td>
                          <td class="brand-box">
                              <span id="brand-name">Ford</span>
                              <select class="form-select" id="brand-select">
                                  <option selected>Select Brand</option>
                                  <option>Ford</option>
                                  <option>Honda</option>
                                  <option>Hyundai</option>
                              </select>
                          </td>
                          <td></td>
                          <td class="action-box">
                              <i id="edit-handle" class="far fa-edit"></i>
                              <i class="far fa-trash-alt"></i>
                          </td>
                      </tr>
                      <tr>
                          <td><input type="checkbox" name =""></td>
                          <td>237</td>
                          <td class="model-box">
                              <span id="model-name">Jazz</span>
                              <input id="model-form" type="text" class="form-control" value="Jazz">
                          </td>
                          <td class="brand-box">
                              <span id="brand-name">Ford</span>
                              <select class="form-select" id="brand-select">
                                  <option selected>Select Brand</option>
                                  <option>Ford</option>
                                  <option>Honda</option>
                                  <option>Hyundai</option>
                              </select>
                          </td>
                          <td></td>
                          <td class="action-box">
                              <i id="edit-handle" class="far fa-edit"></i>
                              <i class="far fa-trash-alt"></i>
                          </td>
                      </tr>
                      <tr>
                          <td><input type="checkbox" name =""></td>
                          <td>238</td>
                          <td class="model-box">
                              <span id="model-name">Jazz</span>
                              <input id="model-form" type="text" class="form-control" value="Jazz">
                          </td>
                          <td class="brand-box">
                              <span id="brand-name">Ford</span>
                              <select class="form-select" id="brand-select">
                                  <option selected>Select Brand</option>
                                  <option>Ford</option>
                                  <option>Honda</option>
                                  <option>Hyundai</option>
                              </select>
                          </td>
                          <td></td>
                          <td class="action-box">
                              <i id="edit-handle" class="far fa-edit"></i>
                              <i class="far fa-trash-alt"></i>
                          </td>
                      </tr>
                      <tr>
                          <td><input type="checkbox" name =""></td>
                          <td>239</td>
                          <td class="model-box">
                              <span id="model-name">Jazz</span>
                              <input id="model-form" type="text" class="form-control" value="Jazz">
                          </td>
                          <td class="brand-box">
                              <span id="brand-name">Ford</span>
                              <select class="form-select" id="brand-select">
                                  <option selected>Select Brand</option>
                                  <option>Ford</option>
                                  <option>Honda</option>
                                  <option>Hyundai</option>
                              </select>
                          </td>
                          <td></td>
                          <td class="action-box">
                              <i id="edit-handle" class="far fa-edit"></i>
                              <i class="far fa-trash-alt"></i>
                          </td>
                      </tr>
                  </tbody>
              </table>
              <nav aria-label="Page navigation example">
                  <ul class="pagination justify-content-end">
                    <li class="page-item disabled">
                      <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
                    </li>
                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item">
                      <a class="page-link" href="#">Next</a>
                    </li>
                  </ul>
              </nav>
          </div>
      </div>
  </main>
</div>
