import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { EventsService } from './events.service';
import { UserService } from './user.service';
import { Location } from '@angular/common'


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    isLoggedIn: Boolean = false;
    isAdmin: Boolean = false;
    constructor(private userService: UserService, private router: Router, private event: EventsService,
        private spinner: NgxSpinnerService, private activated: ActivatedRoute,
        private location: Location) {
        this.event.subscribe("loggedIn", (data) => {
            console.log(data);
            this.isLoggedIn = data;
        })

        this.event.subscribe("isAdmin", (data) => {
            debugger
            console.log(data);
            this.isAdmin = data;
        })

        this.activated.url.subscribe((url: UrlSegment[]) => console.log(url[0].path))

    }

    back(): void {
        this.location.back()
    }

    ngOnInit() {

    }

    login() {
        this.router.navigateByUrl('/appadmin/login');
    }


}
