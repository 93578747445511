import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router'; //import router
import { EventsService } from 'src/app/events.service';
import { UserService } from 'src/app/user.service';

@Component({
    selector: 'app-ad-detail',
    templateUrl: './ad-detail.component.html',
    styleUrls: ['./ad-detail.component.css']
})
export class AdDetailComponent implements OnInit {
    detail: any;
    items = [];
    noData = false;
    params: any;
    constructor(public router: Router, private activeRoute: ActivatedRoute,
        private userService: UserService, private event: EventsService,
    ) {
        if (this.router.url.indexOf("/appadmin") != -1) {
            this.event.publish('isAdmin', true);
        }
    }

    ngOnInit(): void {
        debugger
        this.userService.checkIfUserLoggedIn(1).then((data) => {
            if (data) {
                this.event.publish('loggedIn', true);
            }
        }, (er) => {
            console.log(er)
        })

        console.log(this.router.url)
        this.activeRoute.queryParams.subscribe((params) => {
            debugger
            if (params.item) {
                this.params = JSON.parse(params.item)
                if (this.params.postId)
                    this.detail = this.params;
                this.userAds(this.params.uid);
            }
        })
    }

    approve(val: Boolean) {
        debugger
        let json: any = { action: val ? 'approveAd' : 'declineAd', postId: this.detail?.postId };
        this.userService.getSessionIdToken().then((token: any) => {
            if (token) {
                json.token = token;
                this.userService.presentLoading();
                this.userService.sendPostRequest("adminAdsOps", json).then((data: any) => {
                    console.log(data)
                    if (data.status == "ok") {
                        this.userService.presentToast("success");
                    } else {
                        this.userService.presentToast(data.msg)
                    }
                    this.userService.stopLoading();
                }, (er) => {
                    console.log(er)
                    this.userService.stopLoading();
                });
            }
        })
    }

    userAds(val: any) {
        let json: any = { action: 'getUserAds', uid: val };
        this.userService.getSessionIdToken().then((token: any) => {
            if (token) {
                json.token = token;
                this.userService.sendPostRequest("adminAdsOps", json).then((data: any) => {
                    console.log(data)
                    if (data.status == "ok") {
                        if (data.data && data.data.length == 0) {
                            this.userService.presentToast("No Data found")
                            this.noData = true;
                        }
                        else {
                            this.items = data.data;
                            if (!this.params.postId)
                                this.detail = this.items[0];
                        }
                    } else {
                        this.userService.presentToast(data.msg)
                    }
                }, (er) => {
                    console.log(er)
                });
            }
        })
    }

    details(item: any) {
        this.detail = item;
    }

}
