<div id="layoutSidenav_content">
   <div class="card-body" >
        <div class="form-floating mb-3">
            <input class="form-control" id="inputEmail" [(ngModel)]="email" type="email"
                placeholder="name@example.com" />
            <label for="inputEmail">Email address</label>
        </div>
        <div class="form-floating mb-3">
            <input class="form-control" id="inputPassword" [(ngModel)]="password" type="password"
                placeholder="Password" />
            <label for="inputPassword">Password</label>
        </div>

        <span class="align-items-center justify-content-between mt-4 mb-0">
            <a (click)="login()" class="btn btn-primary">Login</a>
        </span>

    </div>
</div>
<!-- <div id="layoutSidenav_content">
    <main>
        <div class="container-fluid px-4">
            <div class="page-head mt-3 text-center">Ads Settings</div>
            <div class="add-admin-block">
     
                <form class="form-wd">
                    <div class="form-group row">
                      <label for="staticEmail" class="col-sm-6 col-form-label">Banner Ad</label>
                      <div class="col-sm-6">
                        <label class="switch">
                            <input type="checkbox">
                            <span class="slider round"></span>
                          </label>
                         <i class="status-txt"> OFF</i>
                      </div>
                    </div>


                    <div class="form-group row">
                        <label for="staticEmail" class="col-sm-6 col-form-label">Interstitial Ads</label>
                        <div class="col-sm-6">
                          <label class="switch">
                              <input type="checkbox">
                              <span class="slider round"></span>
                            </label>
                            <i class="status-txt"> OFF</i>
                        </div>
                      </div>
                  
                  </form>

                             
              
                <div class="col mt-3">
                    <label for="formGroupExampleInput" class="form-label">interstial start count</label>
                    <input type="text" class="form-control" id="formGroupExampleInput" placeholder="">
                  </div>
                  <div class="col mt-3">
                    <label for="formGroupExampleInput2" class="form-label">interstial offset  count</label>
                    <input type="text" class="form-control" id="formGroupExampleInput2" placeholder="">
                  </div>

                  <div class="col mt-3">
                    <label for="formGroupExampleInput2" class="form-label">interstial max count</label>
                    <input type="text" class="form-control" id="formGroupExampleInput2" placeholder="">
                  </div>
                 
                  <div class="col mt-3">
                      <div class="action-box">
                          <a href="#" class="btn btn-dark-prime">Submit</a>
                      </div>
                  </div>
            </div>
        </div>
    </main>
  
  </div>
   -->