<div id="layoutSidenav_content">
    <main>
        <div class="container-fluid px-4">
            <h1 class="cat-head">User List</h1>
            <div class="page-head">Deleted Users
                <div class="filter-block">
                    <label class="apply-but" (click)=" search()">Apply</label>
                    <label>Filter by:</label>
                    <div class="form-box big-input"><input type="text" id="idMobLoc" class="form-control"
                            placeholder="Search by Mobile, User Name">
                    </div>
                    <div class="form-box"><span>From:</span>
                        <input id="fromDate" type="date" class="form-control" placeholder="Date">
                    </div>
                    <div class="form-box"><span>To:</span>
                        <input id="toDate" type="date" class="form-control" placeholder="Date">
                    </div>
                </div>
            </div>
            <div class="table-box">
                <table class="table-style">
                    <thead>
                        <tr>
                            <th><input type="checkbox" name=""></th>
                            <th>User Id</th>
                            <th>User name</th>
                            <th>Phone</th>
                            <th>Created on</th>
                            <th>Verified on</th>
                            <th></th>
                        </tr>
                    </thead>
                    <!-- <tbody>
                        <tr>
                            <td><input type="checkbox" name=""></td>
                            <td>231</td>
                            <td>Amit Kumar</td>
                            <td>9876543210</td>
                            <td>20-05-2021</td>
                            <td>20-05-2021</td>
                            <td><a (click)="removeUser()" class="btn btn-outlined-dark">Remove</a></td>
                        </tr>

                    </tbody>
                </table> -->
                    <tbody>
                        <tr *ngFor="let item of pageItem;let i=index">
                            <td><input type="checkbox" name=""></td>
                            <td>{{item.uid}}</td>
                            <td>{{item.name}}</td>
                            <td>{{item.mobileNo}}</td>
                            <td>{{item.date| date:'dd-MM-yyyy'}}</td>
                            <td>{{item?.verificationDate| date:'dd-MM-yyyy'}}</td>
                            <td><a (click)="removeUser(item,i)" class="btn btn-outlined-dark">Remove</a></td>

                        </tr>

                    </tbody>
                </table>
                <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-end">
                        <li class="page-item" *ngIf="this.currentPage>1">
                            <a class="page-link" (click)="previous()" tabindex="-1">Previous</a>
                        </li>
                        <li *ngFor="let page of pages" class="page-item"><a
                                [ngClass]="{'highlightPage': this.currentPage == page, 'page-link':true}"
                                (click)="current(page)">{{page}}</a></li>
                        <li class="page-item" *ngIf="this.currentPage != pages.length && pages.length != 0">
                            <a class="page-link" (click)="next()">Next</a>
                        </li>
                        <li class="page-item" *ngIf="this.currentPage == pages.length">
                            <a class="page-link" (click)="loadMore()">Load More..</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </main>

</div>